<template>
  <div class="box">
    <div class="columns is-vcentered">
      <div class="column has-text-centered">
        <p class="has-text-centered"><b>{{song.name}}</b></p>
      </div>
      <div class="column has-text-centered">
        <button class="button is-pdf" @click="getPDF()" v-if="hasFile || hasSong">{{song.id}}</button>
        <p v-else>Tilgængelig</p>
      </div>
    </div>
    <pdf-overlay v-if="showPdf" :overlay-message="song.name" @closed="showPdf = false"
                 :file="song.file" :audio="audioSource(song.mp3)" :has-song="hasSong" :has-file="hasFile"/>
  </div>
</template>

<script>

import PdfOverlay from "./generic/PdfOverlay";
export default {
  name: "Song",
  components: {PdfOverlay},
  props:{
    song:{type: Object},
    isPsalm:{
      type: Boolean,
      default: false
    },
    hasFile:{
      type: Boolean,
      default: true
    },
    hasSong:{
      type: Boolean,
      default: true
    }
  },
  data:()=>({
    showPdf: false,
    path: '/haneropstanden/resources/songs/',
    canPlay: false,
  }),
  computed:{
  },
  methods:{
    audioSource(audio){
      if(audio !== ""){
        /*let songs = require.context('../../resources/songs/', false, /\.mp3$/)
        return songs('./' + audio + ".mp3");*/
        return this.path + audio + ".mp3";
      }else{
        return audio;
      }
    },
    getPDF() {
      this.showPdf = true;
    },
  },
}
</script>

<style scoped>
.media-player{
  /*margin-top: 2vh;*/
}
.is-pdf{
  background-color: #ad0000;
  color: white;
}
.is-pdf:hover{
  background-color: #ff0000;
}
</style>