<template>
  <div class="modal" :class="isActive">
    <div class="modal-background" @click="closeOverlay"></div>
    <div class="modal-content">
      <div class="box ">
        <div class="columns is-vcentered" v-if="hasSong">
          <div class="column has-text-centered">
            <audio controls class="media-player">
              <source :src="audio" type="audio/mpeg">
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
        <div class="columns is-vcentered" v-if="hasFile">
          <div class="column has-text-centered is-message">
            <iframe class="is-pdf" :src="path + file + '.pdf'" v-show="loaded" @load="log()"></iframe>
            <img class="is-img" :src="path + file + '.jpg'" alt="sang">
            <spinner v-if="!loaded"/>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="closeOverlay"></button>
    </div>
  </div>
</template>

<script>

import Spinner from "./Spinner";
export default {
  name: "pdfOverlay",
  components: {Spinner},
  data: () => ({
    isActive: 'is-active',
    path: '/haneropstanden/resources/pdf/',
    loaded: false,
    songLoaded: false,
  }),
  computed:{
  },
  props:{
    overlayMessage:{
      type: String,
      required: true,
    },
    file:{
      type: String,
      required: true,
    },
    audio:{
      type: String,
      required: true,
    },
    hasSong:{
      type: Boolean,
      default: true
    },
    hasFile:{
      type: Boolean,
      default: true
    },
  },
  methods:{
    closeOverlay(){
      this.isActive  = '';
      this.$emit('closed');
    },
    log(){
      this.loaded = true
    }
  },
  created() {
  }
}
</script>

<style scoped>
.is-pdf{
  width: 100%;
  height: 100%;
}
.is-message{
  height: 51em;
}

.media-player{
  width: 100%;
}

@media screen and (max-width: 800px) {
  .is-pdf{
    display: none;
  }
}
@media screen and (min-width: 800px) {
  .is-img{
    display: none;
  }
  .modal-content{
    width: 840px;
  }
}
</style>