<template>
<div class="box is-info-box">
  <div class="box no-shadow">
    <div class="columns is-hidden-mobile">
      <div class="column">
        <p class="is-size-3 has-text-centered">Sang</p>
      </div>
      <div class="column">
        <p class="is-size-3 has-text-centered">Side</p>
      </div>
    </div>
  </div>
  <song v-for="(song, index) in songs" :song="song" :key="index"
        :has-file="hasButton(song.file)" :has-song="hasSong(song.mp3)"></song>
  <waiting-overlay v-if="loading"/>
</div>
</template>

<script>
import Song from "../components/Song";
import WaitingOverlay from "../components/generic/WaitingOverlay";

export default {
name: "Songs",
  components: {WaitingOverlay, Song},
  data:()=>({
    songs: [],
    loading: false,
  }),
  methods:{
    hasButton(file){
      return file !== "";
    },
    hasSong(mp3){
      return mp3 !== "";
    },
    async fetchSongs() {
      const res = await fetch('/haneropstanden/resources/sangs.json').then(res => res.json());
      this.songs = res.songs;
      this.songs.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
    },
  },
  async created() {
    this.loading = true;
    await this.fetchSongs()
    this.loading = false;
  }
}
</script>

<style scoped>
@media screen and (max-width: 800px) {
  .is-info-box{
    width: 90%;
    margin: 20px auto 0 auto;
  }
}
@media screen and (min-width: 800px) {
  .is-info-box{
    width: 60%;
    margin: 20px auto 0 auto;
  }
}

.no-shadow{
  box-shadow:none;
}
</style>