<template>
  <div class="box is-info-box">
    <div class="box no-shadow">
      <div class="columns">
        <div class="column">
          <p class="is-size-3 has-text-centered">Sang</p>
        </div>
        <div class="column">
          <p class="is-size-3 has-text-centered">Lyd</p>
        </div>
      </div>
    </div>
    <song v-for="(song, index) in songs.songs" :song="song" :key="index" :is-psalm="true" :has-file="false"></song>
    <waiting-overlay v-if="loading"/>
  </div>
</template>

<script>
import Song from "../components/Song";
import WaitingOverlay from "../components/generic/WaitingOverlay";

export default {
  name: "Psalms",
  components: {WaitingOverlay, Song},
  data:()=>({
    songs: [],
    loading: false,
  }),
  methods:{
    async fetchSongs() {
      const res = await fetch('/haneropstanden/resources/psalms.json').then(res => res.json());
      this.songs = res;
    },
  },
  async created() {
    this.loading = true;
    await this.fetchSongs()
    this.loading = false;
  }
}
</script>

<style scoped>
.is-info-box{
  width: 80%;
  margin: 20px auto 0 auto;
}
.no-shadow{
  box-shadow:none;
}
</style>