<template>
  <div class="box">
    <div class="columns is-vcentered">
      <div class="column is-hidden-mobile is-one-third" v-if="!leftText && hasImage">
        <div class="container">
          <figure class="image is-256x256">
            <img :src="getImage(image)" :alt="image">
          </figure>
        </div>
      </div>
      <div class="column">
        <p class="org-description is-size-3 title">{{title}}</p>
        <p class="org-description is-size-6 text" v-html="text"></p>
        <p class="control text">
          <a class="button is-primary" @click="getPDF(regulation)" target="_blank" v-if="hasButton">
            Regulamento
          </a>
        </p>
      </div>
      <div class="column is-hidden-mobile is-one-third" v-if="leftText && hasImage">
        <div class="container">
          <figure class="image is-256x256">
            <img class="image" :src="getImage(image)" :alt="image">
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Information",
  data: () =>({
    imageName: true,
    path: '/haneropstanden/resources/news/',
  }),
  props:{
    title:{
      type:String,
    },
    text:{
      type:String,
    },
    image:{
      type:String,
    },
    regulation:{
      type:String,
    },
    leftText:{
      type: Boolean,
      required: true,
    },
    hasButton:{
      type: Boolean,
      default: true,
    },
    hasImage:{
      type: Boolean,
      default: false,
    }
  },
  methods:{
    getImage(image) {
      /*let images = require.context('../../resources/news/', false, /\.jpg$/)
      return images('./' + image )*/
      return this.path + image ;
    },
    getPDF(PDF) {
      window.open('https://centrosocialparoquialdabrandoa.pt/assets/' + PDF + '.pdf', '_blank')
    }
  },
}
</script>

<style scoped>
.image{
  /*margin: 10px 20px 10px 20px;*/
}
.title {
  text-align: justify;
  padding: 20px 40px 0 40px;
}
.text {
  text-align: justify;
  padding: 0 40px 20px 40px;
  margin-top: 5%;
}
.button{
  background-color: #0072bc;
}
.button:hover{
  background-color: #0072bc;
}
@media screen and (min-width: 1400px) {
  .picture{
    margin-top: 10%;
  }
}
@media screen and (max-width: 800px) {
  .title{
    padding: 0;
  }
  .text {
    padding: 0;
  }
}

.is-256x256{
  width: 256px;
  height: 256px;
  margin-left: auto;
  margin-right: auto;
}
</style>