<template>
  <div class="box is-info-box">
    <information v-for="(news, index) in allNews.news" :key="index"
                 :title="news.title" :text="news.text" :image="news.picture" :has-button="false"
                 :has-image="hasImage(news.picture)" :left-text="swapImage(index)">
    </information>
    <waiting-overlay v-if="loading"/>
  </div>
</template>

<script>
import Information from "../components/Information";
import WaitingOverlay from "../components/generic/WaitingOverlay";

export default {
  name: "Informations",
  components: {WaitingOverlay, Information},
  data:()=>({
    allNews: [],
    loading: false,
  }),
  methods:{
    hasImage(image){
      if(image !== ""){
        return true
      }else {
        return false
      }
    },
    swapImage(index){
      return index % 2 === 0;
    },
    async fetchSongs() {
      const res = await fetch('/haneropstanden/resources/news.json').then(res => res.json());
      this.allNews = res;
    },
  },
  async created() {
    this.loading = true;
    await this.fetchSongs()
    this.loading = false;
  }
}
</script>

<style scoped>
.is-info-box{
  width: 80%;
  margin: 20px auto 0 auto;
}
.no-shadow{
  box-shadow:none;
}
</style>